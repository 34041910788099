import React from 'react'

import { ListItem, ListItemText } from '@mui/material'

import Highlighter from 'react-highlight-words'

import NavLink from '../../../../../NavLink'

import { withPages } from '../../../../../../../contexts/PagesContext'

import escapeRegExp from '../../../../../../../util/escapeRegExp'
import contentCleanser from '../../../../../../../util/contentCleanser'

import propTypes from './SearchBoxResult.propTypes'

const SearchBox = ({ convertedPages, route, searchTerm, onClick, body }) => {
  let content = body

  content = contentCleanser(content) // now should be text and some special characters

  const extraReplace = /[#]/g // the terms to keep
  content = content.replace(extraReplace, '')

  const split = route.split('/')
  split.splice(0, 1) // remove leading '/'
  if (split[split.length - 1] === '') {
    split.splice(split.length - 1, 1)
  }

  const titlePieces = []
  let parentPage = convertedPages
  let parts = []
  split.forEach(part => {
    parts.push(part)
    const partsJoined = parts.join('/')
    if (parentPage[partsJoined]) {
      titlePieces.push(parentPage[partsJoined].title)
      parentPage = parentPage[partsJoined]
      parts = []
    }
  })

  const splitSearch = searchTerm.split(' ')

  let secondary = []
  splitSearch.forEach(currentTerm => {
    const trimmedTerm = currentTerm.trim()
    if (trimmedTerm.length > 0) {
      const searchPattern = new RegExp(
        `[^\\n\\.]*${escapeRegExp(currentTerm)}[^\\n\\.]*`,
        'gmi'
      )
      secondary = [...(body.match(searchPattern) || []), ...secondary]
    }
  })

  secondary = [...new Set(secondary)]

  secondary = secondary.map(text => {
    return (
      <>
        ...
        <Highlighter
          searchWords={splitSearch}
          autoEscape
          textToHighlight={text.trim()}
        />
        ...
        <br />
      </>
    )
  })

  if (!content) {
    secondary = 'Loading...'
  }

  const primary = (
    <Highlighter
      searchWords={splitSearch}
      autoEscape
      textToHighlight={titlePieces.join(' > ')}
    />
  )

  return (
    <ListItem button component={NavLink} href={route} onClick={onClick}>
      <ListItemText primary={primary} secondary={secondary} />
    </ListItem>
  )
}

SearchBox.propTypes = propTypes

export default withPages(SearchBox)

const materialTheme = {
  palette: {
    primary: {
      main: '#263238',
      light: '#4f5b62',
      dark: '#000a12'
      // contrastText: '#000000'
    },
    secondary: {
      main: '#607d8b',
      light: '#8eacbb',
      dark: '#34515e'
      // contrastText: '#ffffff'
    }
  }
}

export default materialTheme

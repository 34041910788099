import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import defaultProps from './GoogleRecaptchaWidget.defaultProps'
import propTypes from './GoogleRecaptchaWidget.propTypes'

import captchaKey from '../../../strings/captchaKey'

const GoogleRecaptchaWidget = ({ onChange, disabled }) => {
  return (
    <ReCAPTCHA
      sitekey={captchaKey}
      theme='dark'
      onChange={onChange}
    />
  )
}

GoogleRecaptchaWidget.propTypes = propTypes
GoogleRecaptchaWidget.defaultProps = defaultProps

export default GoogleRecaptchaWidget

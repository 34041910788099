import React from 'react'

import { List } from '@mui/material'

import NavigationItem from './components/NavigationItem'

import defaultProps from './NavigationMenu.defaultProps'
import propTypes from './NavigationMenu.propTypes'

const NavigationMenu = ({ pages, rootRoute }) => {
  const items = pages.map(page => {
    return (
      <NavigationItem
        key={`${page.title}_${page.route}`}
        page={page}
        rootRoute={rootRoute}
      />
    )
  })

  return <List>{items}</List>
}

NavigationMenu.propTypes = propTypes
NavigationMenu.defaultProps = defaultProps

export default NavigationMenu

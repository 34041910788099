import PropTypes from 'prop-types'

const SearchBoxResultPropTypes = {
  /** Pages in an easy to get from route fashion  */
  convertedPages: PropTypes.object.isRequired,
  /** The route to the page */
  route: PropTypes.string.isRequired,
  /** What is currently being searched for */
  searchTerm: PropTypes.string.isRequired,
  /** What to do when this is clicked */
  onClick: PropTypes.func.isRequired
}

export default SearchBoxResultPropTypes

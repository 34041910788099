import { get, cloneDeep } from 'lodash'

const transformErrorsFactory = ({ schema, customErrors }) => {
  const transformErrors = errors => {
    // console.log(errors, schema, customErrors)
    return errors.map(errorIn => {
      const error = cloneDeep(errorIn)
      const splitPath = error.property.split('.')

      const schemaSearch = `${splitPath.join('properties.')}.title`

      splitPath.splice(0, 1)
      const customErrorsSearch = splitPath.join('.')

      const customError = get(
        customErrors,
        `${customErrorsSearch}.${error.name}`
      )
      if (customError) {
        error.message = customError
      }
      error.stack = `${get(schema, schemaSearch, error.property)} ${
        error.message
      }`
      return error
    })
  }
  return transformErrors
}

export default transformErrorsFactory

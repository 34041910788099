import React from 'react'
import { Box, Drawer, useTheme, IconButton } from '@mui/material'
import { Menu } from '@mui/icons-material'

import { withPages } from '../contexts/PagesContext'
import { withNavigationMenu } from '../contexts/NavigationMenuContext'

import AppBar from './components/AppBar'
import Footer from './components/Footer'
import NavigationMenu from './components/NavigationMenu'

import propTypes from './AppLayout.propTypes'
const backgroundColor = '#131313'
const drawerWidth = '30ch'

function AppLayout ({
  pages,
  NavigationMenu: { menuOpen, toggleMenuOpen },
  children
}) {
  const theme = useTheme()
  const actions = (
    <IconButton
      sx={{
        display: {
          xs: 'block',
          sm: 'none'
        }
      }}
      edge='start'
      color='inherit'
      aria-label='menu'
      onClick={toggleMenuOpen}
    >
      <Menu />
    </IconButton>
  )

  return (
    <Box
      display='flex'
      maxWidth='100%'
      sx={{
        background: `linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px,
      linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px,
      linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px,
      linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px,
      linear-gradient(90deg, #1b1b1b 10px, transparent 10px),
      linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424)`,
        backgroundColor,
        backgroundSize: '20px 20px',
        minHeight: '100vh'
      }}
    >
      <Box
        component='nav'
        sx={{
          width: {
            sm: drawerWidth
          },
          flexShrink: {
            sm: 0
          }
        }}
        aria-label='main navigation'
      >
        <Drawer
          variant='temporary'
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={menuOpen}
          onClose={toggleMenuOpen}
          PaperProps={{
            sx: {
              width: drawerWidth,
              bgcolor: `secondary.main`,
              color: '#ffffff',
              display: {
                xs: 'block',
                sm: 'none'
              }
            }
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          <NavigationMenu pages={pages} />
        </Drawer>
        <Drawer
          PaperProps={{
            sx: {
              width: drawerWidth,
              bgcolor: `secondary.main`,
              color: '#ffffff',
              display: {
                xs: 'none',
                sm: 'block'
              }
            }
          }}
          variant='permanent'
          open
        >
          <NavigationMenu pages={pages} />
        </Drawer>
      </Box>
      <Box
        flexGrow={1}
        display='flex'
        flexDirection='column'
        maxWidth='100%'
        minWidth='30px'
      >
        <AppBar actions={actions} />
        <Box color='#ffffff' margin={1} flexGrow={1}>
          {children}
          {/* <ContentRouter pages={pages} /> */}
        </Box>
        <Footer />
      </Box>
    </Box>
  )
}

AppLayout.propTypes = propTypes

export default withNavigationMenu(withPages(AppLayout))

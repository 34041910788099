import PropTypes from 'prop-types'

const withNavigationMenuAttachedPropTypes = {
  /** Navigation menu context */
  NavigationMenu: PropTypes.shape({
    /** Is the menu open? */
    menuOpen: PropTypes.bool.isRequired,
    /** Toggle if the menu is opened or closed */
    toggleMenuOpen: PropTypes.func.isRequired,
    /**
     * Close the menu
     */
    closeMenu: PropTypes.func.isRequired
  }).isRequired
}

export default withNavigationMenuAttachedPropTypes

import { useContext } from 'react'

import GatsbyImageContext from '../GatsbyImageContext'

const useGatsbyImage = () => {
  const gatsbyImage = useContext(GatsbyImageContext)
  return gatsbyImage
}

export default useGatsbyImage

const extendedRegex = /```extended(.|\n|\r)+?```/gm // used to get the extended markdown removed from the indexing
const knownHTMLRegex = /<div style="clear:both"><\/div>/g // just adding this as needed, rather than over engineer right now
const imagesRegex = /!\[[^\]]*]\([^)]*\)/g
const linksRegex = /\[([^\]]*)](\([^)]*\))/g
const quoteRegex = /^> /gm
const newLineFixForFlexSearch = /\n/g

/**
 * Cleanses content to textual
 * @param {string} contentIn The content to cleanse
 *
 * @returns {string} the cleansed content
 */
const contentCleanser = contentIn => {
  let content = contentIn
  content = content.replace(extendedRegex, '')
  content = content.replace(knownHTMLRegex, '')
  content = content.replace(imagesRegex, '')
  content = content.replace(quoteRegex, '')
  content = content.replace(linksRegex, '$1')
  content = content.replace(newLineFixForFlexSearch, '\n ')

  return content
}

module.exports = contentCleanser

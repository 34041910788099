import React from 'react'
import {
  AppBar as MaterialAppBar,
  Box,
  Typography,
  Toolbar
} from '@mui/material'

import CompanyName from '../../../strings/CompanyName'

import SearchBox from './components/SearchBox'

import propTypes from './AppBar.propTypes'
import defaultProps from './AppBar.defaultProps'

const AppBar = ({ actions }) => {
  return (
    <MaterialAppBar position='static'>
      <Toolbar style={{ flexWrap: 'wrap', boxSizing: 'border-box' }}>
        {actions}
        <Typography component='h1' variant='h6'>
          {CompanyName}
        </Typography>
        <Box flexGrow={1} paddingLeft={1} minWidth='10ch' maxWidth='100vw'>
          <SearchBox />
        </Box>
      </Toolbar>
    </MaterialAppBar>
  )
}

AppBar.propTypes = propTypes
AppBar.defaultProps = defaultProps

export default AppBar

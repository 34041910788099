import PropTypes from 'prop-types'

import NavigationMenuProps from '../contexts/NavigationMenuContext/withNavigationMenu.attachesPropTypes'

const AppLayoutPropTypes = {
  pages: PropTypes.array.isRequired,
  ...NavigationMenuProps
}

export default AppLayoutPropTypes

import React, { useState } from 'react'

import { ListItemButton, ListItemText, Collapse, Box } from '@mui/material'
import { useLocation } from '@gatsbyjs/reach-router'

import { ExpandMore, ExpandLess } from '@mui/icons-material'

import NavigationMenu from '../../NavigationMenu'
import NavLink from '../../../NavLink'

import propTypes from './NavigationItem.propTypes'
import defaultProps from './NavigationItem.defaultProps'

import { withNavigationMenu } from '../../../../../contexts/NavigationMenuContext'

const NavigationItem = ({
  page: { title, route, childPages = [], directLink } = {},
  rootRoute = '',
  NavigationMenu: { closeMenu }
}) => {
  const hasChildPages = (childPages || []).length > 0
  const { pathname } = useLocation()

  const finalRoute = `${rootRoute || ''}${route || ''}`

  const initialOpen = pathname.startsWith(finalRoute)

  // !!matchPath(pathname, {
  //   path: finalRoute,
  //   exact: false,
  //   strict: false
  // });

  const [open, setOpen] = useState(initialOpen)

  if (directLink) {
    return (
      <ListItemButton
        key={title}
        component='a'
        href={directLink}
        target='_blank'
        rel='noopener'
        selected={false}
      >
        <ListItemText primary={title} />
      </ListItemButton>
    )
  }

  if (hasChildPages) {
    const handleClick = () => {
      setOpen(!open)
    }
    return (
      <>
        <ListItemButton key={title} onClick={handleClick}>
          <ListItemText primary={title} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout='auto'>
          <Box paddingLeft={2}>
            <NavigationMenu pages={childPages} rootRoute={finalRoute} />
          </Box>
        </Collapse>
      </>
    )
  }

  if (route) {
    return (
      <ListItemButton
        key={title}
        onClick={closeMenu}
        component={NavLink}
        href={finalRoute}
        selected={
          pathname === finalRoute || pathname === `${finalRoute}/`
          // !!matchPath(pathname, {
          //   path: finalRoute,
          //   exact: true,
          //   strict: false
          // })
        }
      >
        <ListItemText primary={title} />
      </ListItemButton>
    )
  }

  return (
    <ListItemButton key={title}>
      <ListItemText primary={title} />
    </ListItemButton>
  )
}

NavigationItem.propTypes = propTypes
NavigationItem.defaultProps = defaultProps

export default withNavigationMenu(NavigationItem)

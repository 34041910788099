import React from 'react'

import { PagesProvider } from './contexts/PagesContext'
import { NavigationMenuProvider } from './contexts/NavigationMenuContext'
import { GatsbyImageProvider } from './contexts/GatsbyImageContext'
import { SnackbarProvider } from 'notistack'
import { StyledEngineProvider } from '@mui/material/styles'

import { SelectedThemeProvider } from './contexts/SelectedThemeContext'

import AppLayout from './AppLayout'
import './App.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

const App = ({ children }) => {
  return (
    <PagesProvider>
      <StyledEngineProvider injectFirst>
        <SnackbarProvider maxSnack={3}>
          <GatsbyImageProvider>
            <SelectedThemeProvider>
              <NavigationMenuProvider>
                <AppLayout>{children}</AppLayout>
              </NavigationMenuProvider>
            </SelectedThemeProvider>
          </GatsbyImageProvider>
        </SnackbarProvider>
      </StyledEngineProvider>
    </PagesProvider>
  )
}

export default App

import React from 'react'

import PagesContext from './PagesContext'

const withPages = WrappedComponent => {
  function WithPages (props) {
    return (
      <PagesContext.Consumer>
        {value => {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <WrappedComponent {...props} pages={value} />
        }}
      </PagesContext.Consumer>
    )
  }
  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component'

  WithPages.displayName = `withPages(${wrappedComponentName})`
  return WithPages
}

export default withPages

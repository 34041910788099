import React from 'react'

import SelectedThemeContext from './SelectedThemeContext'

const withSelectedTheme = WrappedComponent => {
  function WithSelectedTheme (props) {
    return (
      <SelectedThemeContext.Consumer>
        {value => {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <WrappedComponent {...props} selectedTheme={value} />
        }}
      </SelectedThemeContext.Consumer>
    )
  }
  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component'

  WithSelectedTheme.displayName = `withSelectedTheme(${wrappedComponentName})`
  return WithSelectedTheme
}

export default withSelectedTheme

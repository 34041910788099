import React from 'react'

import { Link as MaterialLink, styled } from '@mui/material'
import { CloudDownload } from '@mui/icons-material'

import { Link as GatsbyLink } from 'gatsby'

import { startsWith, trim, toLower } from 'lodash'

import propTypes from './Link.propTypes'

const DownloadIcon = styled(CloudDownload)(({ theme }) => {
  return {
    position: 'relative',
    top: '.25em',
    marginRight: theme.spacing(1),
    color: 'inherit',
    underline: 'always'
  }
})

const themedLinkFunction = ({ theme }) => {
  const primary = theme.palette.primary[theme.palette.type || 'main']
  const effect = {
    backgroundColor: theme.palette.getContrastText(primary),
    color: primary
  }
  return {
    color: 'inherit',
    fontWeight: 'bolder',
    '&:hover': effect,
    '&:focus': effect
  }
}

const StyledGatsbyLink = styled(GatsbyLink)(themedLinkFunction)

const StyledLink = styled(MaterialLink)(themedLinkFunction)

const Link = ({ children, href, ...others }) => {
  let extraChildren = ''
  const toCheck = children[0] || ''

  if (startsWith(toLower(trim(toCheck)), 'download')) {
    extraChildren = <DownloadIcon />
  }

  if (startsWith(href, '../')) {
    return (
      <StyledGatsbyLink to={href}>
        {extraChildren}
        {children}
      </StyledGatsbyLink>
    )
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledLink href={href} {...others} target='_blank'>
      {extraChildren}
      {children}
    </StyledLink>
  )
}

Link.propTypes = propTypes

export default Link

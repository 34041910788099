import PropTypes from 'prop-types'

const withGatsbyImageAttachedPropTypes = {
  /** Properties for gatsbyImage information */
  gatsbyImage: PropTypes.shape({
    /** Images available by path */
    images: PropTypes.objectOf(
      PropTypes.shape({
        childImageSharp: PropTypes.object.isRequired,
        relativePath: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired
}

export default withGatsbyImageAttachedPropTypes

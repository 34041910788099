import React from 'react'

import NavigationMenuContext from './NavigationMenuContext'

import propTypes from './NavigationMenuProvider.propTypes'

class NavigationMenuProvider extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }

  toggleMenuOpen () {
    const { menuOpen } = this.state
    this.setState({
      menuOpen: !menuOpen
    })
  }

  closeMenu () {
    this.setState({
      menuOpen: false
    })
  }

  render () {
    const { children } = this.props
    const { menuOpen } = this.state
    return (
      <NavigationMenuContext.Provider
        value={{
          menuOpen,
          toggleMenuOpen: this.toggleMenuOpen.bind(this),
          closeMenu: this.closeMenu.bind(this)
        }}
      >
        {children}
      </NavigationMenuContext.Provider>
    )
  }
}

NavigationMenuProvider.propTypes = propTypes

export default NavigationMenuProvider

import React from 'react'

import PagesContext from './PagesContext'

import pages from '../../objects/pages'

import propTypes from './PagesProvider.propTypes'

const PagesProvider = ({ children }) => {
  // const data = useStaticQuery(graphql`
  // `)

  return <PagesContext.Provider value={pages}>{children}</PagesContext.Provider>
}

PagesProvider.propTypes = propTypes

export default PagesProvider

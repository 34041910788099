import React, { useState, useRef, useEffect } from 'react'

import { debounce } from 'lodash'

import {
  Box,
  TextField,
  InputAdornment,
  Popper,
  List,
  Paper,
  ClickAwayListener
} from '@mui/material'

import { Search as SearchIcon } from '@mui/icons-material'

import { withPages } from '../../../../../contexts/PagesContext'

import convertPageArrayToRoutePathedObjects from '../../../../../util/convertPageArrayToRoutePathedObjects'

import SearchBoxResult from './components/SearchBoxResult'
import propTypes from './SearchBox.propTypes'

const SearchBox = ({ pages }) => {
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [searchResults, setSearchResults] = useState([])

  const debouncedSearch = debounce(setSearch, 200)

  const inputRef = useRef(null)

  useEffect(() => {
    const getResults = async () => {
      if (!search || search.length < 3) {
        setSearchResults([])
        return
      }
      const results = await fetch('/api/search/' + encodeURIComponent(search))
      const jsonResults = await results.json()
      setSearchResults(jsonResults)
    }
    getResults()
  }, [search])

  const handleClosePopper = () => {
    setOpen(false)
  }

  const handleOpenPopper = () => {
    setOpen(true)
  }

  const convertedPages = convertPageArrayToRoutePathedObjects(pages)

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setTimeout(handleClosePopper, 1)
        }}
      >
        <TextField
          label='Search'
          // id='search'
          helperText='Search terms of three or more characters'
          fullWidth
          onChange={({ target: { value } }) => {
            let toSet = value
            if (toSet.length < 3) {
              toSet = ''
            }

            debouncedSearch(toSet)
          }}
          onFocus={handleOpenPopper}
          inputRef={inputRef}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      </ClickAwayListener>
      {inputRef.current && (
        <Popper
          open={open}
          anchorEl={inputRef.current.parentNode}
          style={{ zIndex: 10000 }}
        >
          {searchResults && searchResults.length > 0 && (
            <Paper>
              <Box
                overflow='auto'
                maxHeight='50vh'
                minWidth={`${inputRef.current.parentNode.clientWidth}px`}
                maxWidth='98vw'
              >
                <List>
                  {(searchResults || []).map(({ route, body }) => {
                    return (
                      <SearchBoxResult
                        onClick={handleClosePopper}
                        key={route}
                        route={route}
                        convertedPages={convertedPages}
                        searchTerm={search}
                        body={body}
                      />
                    )
                  })}
                </List>
              </Box>
            </Paper>
          )}
        </Popper>
      )}
    </>
  )
}

SearchBox.propTypes = propTypes

export default withPages(SearchBox)

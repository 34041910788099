import React from 'react'

import { createTheme } from '@mui/material'

import { merge } from 'lodash'

import SelectedThemeContext from './SelectedThemeContext'

import propTypes from './SelectedThemeProvider.propTypes'

import themeDefaults from './themes/themeDefaults'

// import materialTheme0 from './themes/materialTheme';
import burntTheme from './themes/burntTheme'
// import materialTheme2 from './themes/materialTheme2';
// import materialTheme3 from './themes/materialTheme3';
// import materialTheme4 from './themes/materialTheme4';
// import materialTheme5 from './themes/materialTheme5';
// import materialTheme6 from './themes/materialTheme6';
// import materialTheme7 from './themes/materialTheme7';
// import materialTheme8 from './themes/materialTheme8';
// import materialTheme9 from './themes/materialTheme9';
import darkTheme from './themes/darkTheme'
import steelTheme from './themes/steelTheme'
import poppingTheme from './themes/poppingTheme'

const THEME_KEY = 'SELECTED_THEME'
const VARIANT_KEY = 'SELECT_THEME_VARIANT'

const availableThemes = [
  {
    label: 'Dark',
    value: darkTheme
  },
  {
    label: 'Steel',
    value: steelTheme
  },
  {
    label: 'Popping',
    value: poppingTheme // materialTheme12
  },
  // {
  //   label: 'Medium Blue & Green',
  //   value: materialTheme0
  // },
  {
    label: 'Burnt',
    value: burntTheme
  }
  // {
  //   label: 'Light Blue & Dark Orange',
  //   value: materialTheme2
  // },
  // {
  //   label: 'Light Blue & Red',
  //   value: materialTheme3
  // },
  // {
  //   label: 'Dark Blue & Green',
  //   value: materialTheme4
  // },
  // {
  //   label: 'Eggshell & Dark Green',
  //   value: materialTheme5
  // },
  // {
  //   label: 'Light Green & Dark Gray',
  //   value: materialTheme6
  // },
  // {
  //   label: 'Light Green & Dark Orange',
  //   value: materialTheme7
  // },
  // {
  //   label: 'Light Green & Dark Green',
  //   value: materialTheme8
  // },
  // {
  //   label: 'Mustard Yellow and Bright Blue',
  //   value: materialTheme9
  // },
]

const availableVariants = ['main', 'light', 'dark']

class SelectedThemeProvider extends React.Component {
  constructor (props) {
    super(props)

    const preselectedTheme = JSON.stringify(darkTheme)
    const preselectedVariant = 'main'

    this.state = {
      selectedTheme: JSON.parse(preselectedTheme),
      themeVariant: preselectedVariant
    }
  }

  componentDidMount () {
    const preselectedTheme =
      window.localStorage.getItem(THEME_KEY) || JSON.stringify(darkTheme)
    const preselectedVariant =
      window.localStorage.getItem(VARIANT_KEY) || 'main'

    this.setState({
      selectedTheme: JSON.parse(preselectedTheme),
      themeVariant: preselectedVariant
    })
  }

  setSelectedTheme (selectedTheme) {
    window.localStorage.setItem(THEME_KEY, JSON.stringify(selectedTheme))
    this.setState({ selectedTheme })
  }

  setThemeVariant (themeVariant) {
    window.localStorage.setItem(VARIANT_KEY, themeVariant)
    this.setState({ themeVariant })
  }

  render () {
    const { children } = this.props
    const { selectedTheme, themeVariant } = this.state

    const theme = createTheme(merge({}, themeDefaults, selectedTheme))
    theme.palette.type = themeVariant
    return (
      <SelectedThemeContext.Provider
        value={{
          theme,
          selectedTheme,
          themeVariant,
          availableThemes,
          availableVariants,
          setSelectedTheme: this.setSelectedTheme.bind(this),
          setThemeVariant: this.setThemeVariant.bind(this)
        }}
      >
        {children}
      </SelectedThemeContext.Provider>
    )
  }
}

SelectedThemeProvider.propTypes = propTypes

export default SelectedThemeProvider

const materialTheme = {
  palette: {
    secondary: {
      main: '#263238',
      light: '#4f5b62',
      dark: '#000a12'
      // contrastText: '#000000'
    },
    primary: {
      main: '#212121',
      light: '#484848',
      dark: '#000000'
      // contrastText: '#ffffff'
    }
  }
}

export default materialTheme

import React from 'react'

import NavigationMenuContext from './NavigationMenuContext'

const withNavigationMenu = WrappedComponent => {
  function WithNavigationMenu (props) {
    return (
      <NavigationMenuContext.Consumer>
        {value => {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <WrappedComponent {...props} NavigationMenu={value} />
        }}
      </NavigationMenuContext.Consumer>
    )
  }
  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component'

  WithNavigationMenu.displayName = `withNavigationMenu(${wrappedComponentName})`
  return WithNavigationMenu
}

export default withNavigationMenu

const materialTheme = {
  palette: {
    // type: 'main',
    primary: {
      main: '#dd2c00',
      light: '#ff6434',
      dark: '#a30000'
      // contrastText: '#ffffff'
    },
    secondary: {
      main: '#304ffe',
      light: '#7a7cff',
      dark: '#0026ca'
      // contrastText: '#ffffff'
    }
  }
}

export default materialTheme

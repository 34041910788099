import PropTypes from 'prop-types'

const pageShape = PropTypes.shape({
  /** The display title of the page */
  title: PropTypes.string.isRequired,
  /** The type of the file this page is represented by */
  type: PropTypes.string,
  /** The route that this page can be seen at */
  route: PropTypes.string.isRequired
})

pageShape.childPages = PropTypes.arrayOf(pageShape)

const withPagesAttachedPropTypes = {
  /** The array of all available pages */
  pages: PropTypes.arrayOf(pageShape).isRequired
}

export default withPagesAttachedPropTypes

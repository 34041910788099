import PropTypes from 'prop-types'

const NavigationItemPropTypes = {
  /** The page defining what is displayed by the navigation item */
  page: PropTypes.shape({
    /** The title defines what words are shown */
    title: PropTypes.string.isRequired,
    /** Sub pages, could go several level's deep if needed */
    childPages: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  /** Root route to append to if necessary */
  rootRoute: PropTypes.string
}

export default NavigationItemPropTypes

import React from 'react'

import { cloneDeep } from 'lodash'

import { Button, Box } from '@mui/material'

import MuiForm from '@rjsf/mui'
import validator from '@rjsf/validator-ajv8'

import GoogleRecaptchaWidget from './components/GoogleRecaptchaWidget'
import transformErrorsFactory from './components/transformErrorsFactory'

import propTypes from './MaterialJSONSchemaForm.propTypes'
import defaultProps from './MaterialJSONSchemaForm.defaultProps'

class MaterialJSONSchemaForm extends React.Component {
  constructor (props) {
    super(props)

    this.recaptchaRef = React.createRef()
  }

  render () {
    const {
      schema,
      uiSchema,
      disabled,
      customErrors,
      noSubmit,
      noCaptcha,
      ...others
    } = this.props

    const finalSchema = cloneDeep(schema || {})
    const finalUISchema = cloneDeep(uiSchema || {})
    const finalCustomErrors = cloneDeep(customErrors || {})
    if (!noCaptcha) {
      finalSchema.required = finalSchema.required || []
      finalSchema.required.push('recaptcha')
      finalSchema.properties.recaptcha = {
        type: 'string',
        title: 'ReCaptcha'
      }
      finalCustomErrors.recaptcha = {
        required: 'must be completed',
        string: 'must be completed'
      }
      finalUISchema.recaptcha = { 'ui:widget': GoogleRecaptchaWidget }
    }

    return (
      <MuiForm
        disabled={disabled}
        transformErrors={transformErrorsFactory({
          schema: finalSchema,
          customErrors: finalCustomErrors
        })}
        schema={finalSchema}
        uiSchema={finalUISchema}
        validator={validator}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...others}
      >
        <Box>
          {!noSubmit && (
            <Button
              disabled={disabled}
              variant='contained'
              fullWidth
              color='primary'
              type='submit'
            >
              Submit
            </Button>
          )}
        </Box>
      </MuiForm>
    )
  }
}

MaterialJSONSchemaForm.propTypes = propTypes
MaterialJSONSchemaForm.defaultProps = defaultProps

export default MaterialJSONSchemaForm

import PropTypes from 'prop-types'

const MaterialJSONSchemaFormPropTypes = {
  /** The JSON Schema to create the form. */
  schema: PropTypes.object.isRequired,
  /** The extra UI Schema used when creating the form */
  uiSchema: PropTypes.object,
  /** Should the form be disabled */
  disabled: PropTypes.bool,
  /** An object composed of custom errors of form {property: {error-type: 'message'}} */
  customErrors: PropTypes.object,
  /** Do not allow the submit button */
  noSubmit: PropTypes.bool,
  /** Do not show captcha */
  noCaptcha: PropTypes.bool
}

export default MaterialJSONSchemaFormPropTypes

import React from 'react'

import GatsbyImageContext from '../GatsbyImageContext'

const GatsbyImageProvider = ({ children, images }) => {
  return (
    <GatsbyImageContext.Provider value={{ images }}>
      {children}
    </GatsbyImageContext.Provider>
  )
}

export default GatsbyImageProvider

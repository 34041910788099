const convertPageArrayToRoutePathedObjects = (pages = []) => {
  const toReturn = {}
  pages.forEach(({ route = '', childPages = [], ...others }) => {
    const routed = route.replace('/', '')
    if (routed !== '') {
      toReturn[routed] = { ...others }

      const childConverted = convertPageArrayToRoutePathedObjects(childPages)
      toReturn[routed] = Object.assign(toReturn[routed], childConverted)
    }
  })
  return toReturn
}

export default convertPageArrayToRoutePathedObjects

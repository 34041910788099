import React from 'react'

import { createTheme } from '@mui/material'
import darkTheme from './themes/darkTheme'

const SelectedThemeContext = React.createContext({
  theme: createTheme(darkTheme),
  selectedTheme: darkTheme,
  themeVariant: 'main',
  availableThemes: ['dark'],
  availableVariants: ['main', 'dark', 'light'],
  setSelectedTheme: () => {},
  setThemeVariant: () => {}
})

export default SelectedThemeContext

import React from 'react'

import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Hidden,
  Button
} from '@mui/material'

import Link from '../../../MarkdownToMaterial/renderers/Link'

import { Menu, Facebook } from '@mui/icons-material'

import { isEqual } from 'lodash'
import CompanyName from '../../../strings/CompanyName'

import { withSelectedTheme } from '../../../contexts/SelectedThemeContext'
import { withNavigationMenu } from '../../../contexts/NavigationMenuContext'

import MaterialJSONSchemaForm from '../../../MaterialJSONSchemaForm'

import propTypes from './Footer.propTypes'

const Footer = ({
  selectedTheme: { selectedTheme, availableThemes, setSelectedTheme },
  NavigationMenu: { toggleMenuOpen }
}) => {
  const themeSchema = {
    type: 'object',
    properties: {
      selectedTheme: {
        title: 'Site Theme',
        type: 'string',
        anyOf: availableThemes.map(({ label, value }) => {
          return {
            type: 'string',
            title: label,
            enum: [JSON.stringify(value)]
          }
        }),
        default: JSON.stringify(selectedTheme)
      }
    }
  }

  const onChangeTheme = ({ formData }) => {
    if (
      formData.selectedTheme &&
      !isEqual(JSON.stringify(selectedTheme), formData.selectedTheme)
    ) {
      setSelectedTheme(JSON.parse(formData.selectedTheme))
    }
  }

  const copyRight = `\u00A9${CompanyName} 2012-${new Date().getFullYear()}`

  return (
    <AppBar component='footer' position='static' color='primary'>
      <Toolbar>
        <Box paddingBottom={1} width='100%'>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4} md={4} lg={3}>
              <Box>
                <MaterialJSONSchemaForm
                  noCaptcha
                  noSubmit
                  schema={themeSchema}
                  onChange={onChangeTheme}
                />
              </Box>
            </Grid>
            <Hidden smUp implementation='js'>
              <Grid item xs={12}>
                <Button
                  color='secondary'
                  variant='contained'
                  fullWidth
                  startIcon={<Menu />}
                  onClick={toggleMenuOpen}
                >
                  Toggle Navigation
                </Button>
              </Grid>
            </Hidden>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Box
                display='flex'
                height='100%'
                alignItems='center'
                justifyContent='flex-end'
                textAlign='right'
              ></Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={5}>
              <Box
                display='flex'
                height='100%'
                alignItems='center'
                justifyContent='flex-end'
                textAlign='right'
              >
                <Box>
                  <Box display='flex' justifyContent='flex-end'>
                    <Link
                      target='_blank'
                      href='https://www.facebook.com/Michael-Adsit-Technologies-LLC-103559258066575/'
                    >
                      <Facebook aria-label='Find Us On Facebook' />
                    </Link>
                  </Box>
                  <Box>
                    <Typography>{copyRight}</Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

Footer.propTypes = propTypes

export default withNavigationMenu(withSelectedTheme(Footer))

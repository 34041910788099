const materialTheme = {
  palette: {
    // type: 'main',
    primary: {
      main: '#1a237e',
      light: '#534bae',
      dark: '#000051'
      // contrastText: '#ffffff'
    },
    secondary: {
      main: '#d84315',
      light: '#ff7543',
      dark: '#9f0000'
      // contrastText: '#000000'
    }
  }
}

export default materialTheme

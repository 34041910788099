const themeDefaults = {
  typography: {
    h1: {
      fontSize: '3rem',
      fontWeight: '700',
      textAlign: 'center'
    },
    h2: {
      fontSize: '2rem',
      fontWeight: '450',
      textAlign: 'center'
    },
    h3: {
      fontSize: '2rem',
      textAlign: 'center'
    },
    h4: {
      fontSize: '1.75rem',
      textAlign: 'center'
    },
    h5: {
      fontSize: '1.5rem',
      textAlign: 'center'
    },
    h6: {
      fontSize: '1.25rem',
      textAlign: 'center'
    }
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'filled'
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'filled'
      },
      styleOverrides: {
        icon: {
          color: 'currentColor'
        }
      }
    },
    MuiInputLabel: {
      defaultProps: {
        variant: 'filled'
      },
      styleOverrides: {
        root: {
          color: 'currentColor'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: 'currentColor'
        },
        root: {
          color: 'currentColor',
          '&.Mui-disabled': {
            color: 'currentColor',
            opacity: '38%'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'currentColor',
          '&.Mui-focused': {
            color: 'currentColor'
          },
          '&.Mui-disabled': {
            opacity: '38%',
            color: 'currentColor'
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255,255,255,.1)'
        },
        underline: {
          '&::before, &::after': {
            borderBottom: '1px solid currentColor'
          },
          '& label.Mui-focused': {
            color: 'currentColor'
          }
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: 'currentColor'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: 'currentColor'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: null,
            opacity: '38%',
            backgroundColor: null
          }
        },
        contained: {
          '&.Mui-disabled': {
            color: null,
            opacity: '38%',
            backgroundColor: null
          }
        }
      }
    }
  }
}

export default themeDefaults

import * as React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, createTheme } from '@mui/material/styles'

import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

import darkTheme from '../../src/components/contexts/SelectedThemeContext/themes/darkTheme'
import defaultTheme from '../../src/components/contexts/SelectedThemeContext/themes/themeDefaults'

const theme = createTheme({
  ...darkTheme,
  ...defaultTheme
})

function createEmotionCache () {
  return createCache({ key: 'css' })
}

export default function TopLayout (props) {
  const cache = createEmotionCache()

  return (
    <React.Fragment>
      <Helmet>
        <meta name='viewport' content='initial-scale=1, width=device-width' />
        <link
          href='https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap'
          rel='stylesheet'
        />
      </Helmet>
      <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          {props.children}
        </ThemeProvider>
      </CacheProvider>
    </React.Fragment>
  )
}

TopLayout.propTypes = {
  children: PropTypes.node
}

import PropTypes from 'prop-types'

const withSelectedThemeAttachedPropTypes = {
  /** Selected theme is injected by context */
  selectedTheme: PropTypes.shape({
    /** Current theme */
    selectedTheme: PropTypes.object.isRequired,
    /** Current Theme Variant */
    themeVariant: PropTypes.string.isRequired,
    /** Themes available to use */
    availableThemes: PropTypes.arrayOf(
      PropTypes.shape({
        /** What to show for display purposes */
        label: PropTypes.string.isRequired,
        /** What the actual theme values are */
        value: PropTypes.object.isRequired
      }).isRequired
    ),
    /**
     * Theme Variants available for use
     */
    availableVariants: PropTypes.arrayOf(PropTypes.string.isRequired)
      .isRequired,
    /** A function to set the selected theme */
    setSelectedTheme: PropTypes.func.isRequired,
    /** A function to set the selected theme variant */
    setThemeVariant: PropTypes.func.isRequired
  }).isRequired
}

export default withSelectedThemeAttachedPropTypes
